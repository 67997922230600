import React from 'react'
import styled from 'styled-components'
import {minWidth, maxWidth} from '../themes/default'
import Logo from './svg/logo'
// https://github.com/contra/react-responsive or https://github.com/ReactTraining/react-media
import MediaQuery from 'react-responsive';

const Content = ({ children }) => 

<ContentStyled>
  

  <div className="content">
    <MediaQuery query={maxWidth.desktop}>
      <div className="logo" style={{ width: `50px`, float: `right` }}>
        
          <Logo color="#fea04f" />
        
        
      </div>
    </MediaQuery>
      {children}
    
  </div>
</ContentStyled>

export default Content

// styles

const ContentStyled = styled.section`

    width:100%;
    order:1;
    display: flex;
    

 


    @media ${minWidth.desktop} {
      width:65%;
    } 
    
    

    .content {
      padding: 2rem;
      margin: auto;
      max-width: ${props => props.theme.wide.smallDesktop}
    }
    
`
