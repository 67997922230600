import React from 'react'


const logo = (props) => 


<svg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' fillRule='evenodd'
    clipRule='evenodd' strokeLinejoin='round' strokeMiterlimit='1.414'>
        <path d='M135.591,481.931c23.635,-20.329 56.848,-54.83 99.639,-103.501c60.991,-69.529 109.174,-132.959 144.548,-190.291c-25.616,68.31 -46.963,154.104 -64.04,257.381c-2.033,13.418 -2.033,22.363 0,26.836c1.626,4.879 7.929,10.978 18.907,18.297c3.122,2.172 6.08,4.163 8.874,5.972c-27.311,9.948 -56.786,15.375 -87.519,15.375c-43.516,0 -84.51,-10.881 -120.409,-30.069Zm333.524,-367.739c27.089,40.606 42.885,89.377 42.885,141.808c0,91.05 -47.636,171.062 -119.324,216.451c13.454,-53.206 37.82,-105.893 73.099,-158.061c3.253,-4.879 4.879,-9.148 4.879,-12.808c0,-6.099 -3.049,-9.148 -9.148,-9.148c-6.506,0 -12.605,3.659 -18.297,10.978c-2.44,2.033 -8.641,8.945 -18.603,20.737c-9.961,11.791 -17.992,20.533 -24.091,26.226c13.079,-64.647 35.946,-143.375 68.6,-236.183Zm-106.268,-90.863c15.871,7.308 30.869,16.192 44.793,26.452c-3.547,10.852 -10.395,28.306 -20.543,52.362c-15.044,35.374 -49.199,87.826 -102.464,157.356c-52.046,67.089 -100.838,122.794 -146.378,167.114c-16.486,16.486 -31.006,25.861 -43.558,28.125c-6.258,-5.087 -12.272,-10.464 -18.021,-16.109c0.062,-2.042 0.259,-4.217 0.588,-6.527c10.979,-65.057 43.304,-138.245 96.976,-219.566c46.353,-70.749 88.639,-120.152 126.86,-148.208c17.484,-13.011 40.661,-13.418 69.53,-1.219c2.846,1.219 5.895,1.829 9.148,1.829c8.539,0 12.808,-3.253 12.808,-9.758c0,-3.66 -1.829,-7.116 -5.489,-10.369c-8.673,-7.985 -16.756,-15.146 -24.25,-21.482Zm-335.537,347.771c-17.468,-34.61 -27.31,-73.715 -27.31,-115.1c0,-141.29 114.71,-256 256,-256c3.96,0 7.898,0.09 11.814,0.269c-5.011,3.565 -10.18,7.841 -15.506,12.827c-58.958,54.892 -111.207,122.287 -156.746,202.184c-32.46,56.95 -55.21,108.889 -68.252,155.82Z'
        fill={props.color} />
</svg>
//'#21212a'
//'#fea04f'

export default logo
