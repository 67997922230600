import React from "react"
// import { Link } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layouts/layout"
import Content from "../components/helpers/Content"
import Aside from "../components/helpers/Aside"
import styled from 'styled-components'
import {minWidth} from '../components/themes/default' // maxWidth

// https://github.com/contra/react-responsive or https://github.com/ReactTraining/react-media
import MediaQuery from 'react-responsive';

// import Image from "../components/image"
// import SEO from "../components/seo"

const IndexPage = () => (
   
    <Layout>
      <SEO title="FullStack Developer" />
      
      <Content >
        <h1 style={{fontSize: `3rem`}}>Hi, I'm Bogdan</h1>
        <p>As a frontend developer I carefully craft responsive layouts with <Span className="highlight">web performance</Span> in mind.</p>
        <p>As a <Span className="highlight">backend developer</Span> I transform  WordPress into a powerful tool.</p>


        <p>As a React developer I'm allowed to dream to infinite possibilities. <br />Rendering content based on <Span className="highlight">device or network speed</Span>?! No problem!</p>
        <p>As a designer I like to be playful. <br />But if an <Span className="highlight">A/B testing</Span> or any other <Span className="highlight">UX</Span> analyse will tell me to be sober, then I'll comply.</p>
        <p>What am I? Maybe some would call me a full stack developer and an UX designer.
          <br />But <Span className="dreamer" style={{fontSize: `160%`}}>I'm</Span>
                  <Span className="dreamer" style={{fontSize: `240%`}}>just</Span>
                  <Span className="dreamer" style={{fontSize: `320%`}}>a</Span>
                  <Span className="dreamer" style={{fontSize: `400%`}}>dreamer</Span>
        </p>
        </Content>

      
      
      <MediaQuery query={minWidth.desktop}>
        <Aside image="profile-opt.jpg" isWidth="1900px" isLeft="-800px" /> {/* title="Hello. My name is Bogdan Andronic and I'm a full stack developer."  */ }
      </MediaQuery>

            
    </Layout>
    
)

export default IndexPage

const Span = styled.span`
  &.dreamer {
    padding: 0 10px;
    display: inline-block;

    /* @media ${minWidth.desktop} {
      padding: 30px;
    } */
  }
  &.highlight {
    color: ${props => props.theme.color.highlight}
  }
`
